<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Update Email Template</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <EmailForm @submitted="updateEmailTemplate" :email="email" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import EmailForm from '@/components/EmailForm'
import { firestore } from '@/services/firebase'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { EmailForm, BaseBreadcrumb },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const email = ref({})
    const breadcrumbs = [
      { label: 'Email Templates', to: '/emails' },
      { label: 'Update' }
    ]

    function getEmailTemplate() {
      firestore
        .collection('handlebar')
        .doc(route.params.id)
        .get()
        .then(doc => {
          if (doc.exists) {
            email.value = doc.data()
          }
        })
    }

    function updateEmailTemplate(formData) {
      firestore
        .collection('handlebar')
        .doc(route.params.id)
        .set(formData, { merge: true })
        .then(() => {
          router.push({ name: 'EmailIndex' })
        })
    }

    getEmailTemplate()

    return { updateEmailTemplate, email, breadcrumbs }
  }
}
/*
 * https://medium.com/@willbeh/firebase-extensions-trigger-email-custom-template-with-handlebar-7d15e56be72c
 * https://github.com/firebase/extensions/blob/master/firestore-send-email/POSTINSTALL.md
 */
</script>
